@import "../../Variables.scss";

.partnership-container {
  .partnership-section {
    background-image: url("/images/Partnership_Images/partnership-bg-v2.svg");
    background-size: cover;
    background-position: center -100px;
    // margin-top: 200px;
    height: max-content;
    display: flex;
    flex-direction: column;
    height: 1000px;

    @media only screen and (max-width: 800px) {
      height: 1400px;
    }

    .partnership-header {
      position: relative;
      margin-top: 230px;

      @media only screen and (max-width: 800px) {
        margin-top: 430px;
      }

      .section-title {
        // margin-top: 100px;
        position: relative;
        height: 200px;
        display: flex;
        flex-direction: row;
        justify-content: center;
      }

      .partnership-title-left {
        position: relative;
        width: 200px;
        height: 300px;

        @media only screen and (max-width: 800px) {
          font-size: 16px;
          margin-top: auto;
          height: fit-content;
          margin-bottom: 20px;
          display: none;
        }
      }

      .partnership-title-right {
        position: relative;
        width: 200px;
        height: 300px;
        // position: absolute;
        // left: 50%;
        // transform: translate(80%, 0);
        // top: 280px;
        // text-align: left;

        @media only screen and (max-width: 800px) {
          font-size: 16px;
          margin-top: auto;
          margin-bottom: 20px;
          height: fit-content;
          display: none;
        }
      }

      .section-illustration {
        position: relative;
        flex-shrink: 0;

        // position: absolute;
        // top: 185px;
        // left: 50%;
        // transform: translate(-50%, 0);
        width: 200px;
        height: 200px;
        background-image: url("/images/Partnership_Images/partnership-illustration-v1.png");
        background-size: cover;
      }
    }
  }

  .boxed-description {
    position: relative;
    max-width: 900px;
    margin: 50px auto 0 auto;
    padding: 20px;

    // @media only screen and (max-width: 800px) {
    //   width: 100%;
    // }

    .description-subtext {
      display: none;
      @media only screen and (max-width: 800px) {
        display: block;
      }
    }

    .read-more-button {
      margin: 20px 20px 0 auto;
    }

    .description-text {
      padding: 30px;
      @media only screen and (max-width: 800px) {
        font-size: 15px;
      }
    }
  }
}
