@import "../../Variables.scss";

.become-a-coach-container {
  margin-top: 300px;

  @media only screen and (max-width: 850px) {
    margin-top: 0px;
    margin-bottom: 300px;
  }

  .become-a-coach-section {
    background-image: url("/images/BecomeACoach_Images/be-a-coach-bg.svg");
    background-size: cover;
    background-position: left center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 800px;

    @media only screen and (max-width: 1000px) {
      height: 1200px;
      // min-height: max-content;
      flex-direction: column;
      justify-content: center;
      background-size: 2000px;
      background-position: left center;
    }

    @media only screen and (max-width: 550px) {
      background-size: 1500px;
    }

    .section-header {
      position: relative;
      display: flex;
      flex-direction: row;
      width: 600px;
      margin-top: 140px;
      margin-right: 100px;

      @media only screen and (max-width: 1000px) {
        margin-top: 300px;
      }

      .section-title {
        // position: absolute;
        // top: 340px;
        // left: 130px;
        // font-size: 20px;
        // margin-top: 100px;
        // margin-left: 80px;
        text-align: right;

        @media only screen and (max-width: 800px) {
          font-size: 18px;
          left: 80px;
          top: 340px;
        }

        .uppercase-bolded-font {
          margin-right: 60px;
        }

        .custom-spacing {
          margin-right: 20px;
        }
      }

      .section-illustration {
        position: relative;
        background-image: url("/images/BecomeACoach_Images/be-a-coach-illustration.png");
        background-size: 100%;
        background-position: center center;
        // margin-top: 10px;
        margin-left: 150px;

        // top: 220px;
        // left: 240px;

        @media only screen and (max-width: 550px) {
          // left: 50%;
          // position: absolute;
          margin-left: 10%;
          // left: 50%;
          top: 50%;
          transform: translate(0, 20%);
        }

        // @media only screen and (max-width: 650px) {
        //   left: 180px;
        // }
      }
    }

    .boxed-description {
      margin-top: 100px;
      margin-right: 50px;
      flex-grow: 1;
      max-width: 900px;
      border-radius: 24px;
      background-color: transparent;

      @media only screen and (max-width: 1000px) {
        margin: 200px auto 0 auto;
      }

      .description-text {
        background-color: rgba(255, 255, 255, 0.486);
        padding: 30px;
        border-radius: 24px;

        @media only screen and (max-width: 1000px) {
          background-color: transparent;
        }
      }

      .read-more-button {
        margin: 20px 20px 0 auto;
      }

      .description-subtext {
        margin: 0 30px 0 auto;
        max-width: 500px;
        text-align: right;

        @media only screen and (max-width: 1000px) {
          text-align: left;
          margin: 0;
          padding: 0 0 0px 30px;
        }
      }
    }
  }
}
