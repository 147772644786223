@import "/src/Variables.scss";

a {
  text-decoration: none;
}

.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
}

.App-container {
  position: relative;
  width: 100%;
  height: fit-content;
}

.App-section {
  position: relative;
  height: fit-content;
  width: 100%;
  background-repeat: no-repeat;
  // margin: 100px 0;

  @media only screen and (max-width: 900px) {
    height: 800px;
  }
}

a {
  color: $color-blue;
  text-decoration: underline;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.blue-font {
  color: $color-blue;
}

.gray-font {
  color: $color-gray;
}

.large-font {
  font-size: 30px;
  @media only screen and (max-width: 650px) {
    font-size: 25px;
  }
}

.medium-font {
  font-size: 20px;

  @media only screen and (max-width: 650px) {
    font-size: 18px;
  }
}

.small-font {
  font-size: 16px;
  @media only screen and (max-width: 650px) {
    font-size: 14px;
  }
}

.uppercase-bolded-font {
  text-transform: uppercase;
}

.text-shadow {
  text-shadow: 2px 2px #dfdfdf;
}

.section-illustration {
  position: relative;
  width: 200px;
  height: 200px;
  background-repeat: no-repeat;
  flex-shrink: 0;
}

.large-text {
  text-transform: uppercase;
  color: #00afef;
  font-size: 40px;
  margin-right: 15px;
}

.small-text {
  margin: auto 0;
}

.short-description {
  font-size: 15px;
  color: rgb(107, 107, 107);
  width: 60%;
  max-width: 800px;
  margin-top: 20px;
  text-align: left;
}

.boxed-description {
  position: relative;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  // background-color: rgba(255, 255, 255, 0.486);
  padding: 15px 20px;
}

.description-title {
  font-size: 24px;
  color: $color-gray;
  text-align: right;
}

.description-subtext {
  position: relative;
  font-size: 18px;
  padding: 5px 20px;
}

.description-text {
  position: relative;
  height: fit-content;
  font-size: 15px;
  text-align: left;
}

.read-more-button {
  position: relative;
  display: flex;
  flex-direction: row;
  width: max-content;

  &:hover {
    .read-more-line {
      border-top: 2px solid $color-blue;
    }

    .read-more-arrow {
      border-left: 10px solid $color-blue;
    }
  }

  .read-more-line {
    margin: auto;
    border-top: 2px solid transparent;
    width: 18px;
    transition: all 0.5s;
  }

  .read-more-arrow {
    margin: auto;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-left: 9px solid transparent;
    border-bottom: 7px solid transparent;
    transition: all 0.5s;
  }

  .read-more-text {
    width: max-content;
    padding: 14px 18px;
    border: 1px solid $color-blue;
    box-shadow: 0px 0px 5px 5px rgb(229, 229, 229);
    background-color: rgba(255, 255, 255, 0.886);
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.5s;

    a {
      color: black;
      font-weight: 500;
      text-decoration: none;
    }

    &:hover {
      // border: 1px solid $color-blue;
      background-color: $color-blue;
      font-size: 17px;

      a {
        color: white;
      }
    }
  }
}

// .read-more {
//   position: relative;
//   background-color: #00afef;
//   width: 150px;
//   height: 50px;
//   font-size: 18px;
//   cursor: pointer;
//   flex-shrink: 0;
// }

// .read-more::after {
//   content: "";
//   position: absolute;
//   right: -30px;
//   top: 0px;
//   width: 0;
//   height: 0;
//   border-top: 25px solid transparent;
//   border-left: 30px solid #00afef;
//   border-bottom: 25px solid transparent;
// }

// .read-more-text {
//   position: relative;
//   margin-top: 10px;
//   color: white;
// }

// .read-more-text > a {
//   position: relative;
//   margin-top: 10px;
//   color: white;
// }

.App-link {
  color: #61dafb;
}

.footer {
  width: 100%;
  height: 50px;
}

.copyright-text {
  font-size: 16px;
  color: rgb(105, 105, 105);
}

.bullet-list {
  display: flex;
  flex-direction: row;
  margin: 5px;

  .list-icon {
    margin: 7px 12px 0 0;
    width: 1px;
    height: 1px;
    border: solid $color-blue;
    border-width: 0 4px 4px 0;
    border-radius: 3px;
    display: inline-block;
    padding: 2px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  // .list-icon {
  //   margin: 5px 5px 0 0;
  //   width: 0;
  //   height: 0;
  //   border-top: 5px solid transparent;
  //   border-left: 7px solid $color-blue;
  //   border-bottom: 5px solid transparent;
  //   // transition: all 0.5s;
  // }

  // .list-icon {
  //   width: 10px;
  //   height: 10px;
  //   background-color: $color-blue;
  //   box-shadow: 2px 2px rgb(235, 235, 235);
  //   border-radius: 50%;
  //   // background-image: url("/images/blue-star.png");
  //   // background-repeat: no-repeat;
  //   // background-size: cover;
  //   flex-shrink: 0;
  //   margin-right: 10px;
  //   margin-top: 7px;
  // }

  .list-text {
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 600px) {
  .App-header {
    background-position: center 60px;
    height: max-content;
    margin-top: 100px;
    margin-bottom: 150px;
  }

  .company-name {
    font-size: 30px;
    /* color: #00afef;
    text-shadow: 2px 2px #dfdfdf; */
  }

  .slogan {
    font-size: 16px;
  }

  .large-text {
    text-transform: uppercase;
    color: #00afef;
    font-size: 30px;
  }

  .Contact-section {
    width: 100%;
    background-size: 150%;
  }

  .contact-section-title {
    background-size: 80%;
  }

  .contact-buttons-holder {
    width: 100%;
  }
  .contact-button {
    width: 100%;
  }

  .email-button {
    flex-direction: column;
  }

  .email-icon {
    margin: 0 auto;
  }

  .email-text {
    margin: auto;
    font-size: 14px;
  }

  .copyright-text {
    font-size: 12px;
    padding: 0 20px;
  }

  // .read-more {
  //   width: 110px;
  //   font-size: 15px;
  // }

  // .read-more-text {
  //   margin-top: 11px;
  // }

  // .read-more-text > a {
  //   margin-top: 11px;
  // }
}
