@import "../../Variables.scss";

.get-coaching-container {
  position: relative;
  width: 100%;
  height: fit-content;

  .get-coaching-section {
    background-image: url("/images/GetCoaching_Images/get-coaching-bg.svg");
    background-size: cover;
    background-position: right center;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    height: 900px;

    @media only screen and (max-width: 900px) {
      background-position: right -100px;
      flex-direction: column;
      justify-content: start;
      font-size: 18px;
      height: 1100px;
      background-size: 1400px 1000px;
    }

    .section-illustration {
      background-image: url("/images/GetCoaching_Images/get-coaching-illustration.png");
      background-size: 100%;
      background-position: center center;
      width: 300px;
      height: 300px;
      margin-top: 160px;

      @media only screen and (max-width: 900px) {
        margin: 105px auto 100px auto;
      }
    }

    .boxed-description {
      flex-grow: 1;
      max-width: 1000px;
      border-radius: 3px;
      margin-right: 100px;
      margin-top: 160px;

      @media only screen and (max-width: 900px) {
        flex-grow: 0;
        margin-right: 0;
        margin-top: 0;
      }

      .read-more-button {
        margin: 20px 0 0 30px;

        @media only screen and (max-width: 900px) {
          margin: 20px 20px 0 auto;
        }
      }

      .description-text {
        background-color: rgba(255, 255, 255, 0.486);
        // border-radius: 24px;
        padding: 30px;
        max-width: 800px;
      }

      .description-subtext {
        text-align: left;
        word-spacing: 5px;
        margin-left: 10px;

        @media only screen and (max-width: 900px) {
          text-align: center;
          margin: 0;
          // padding: 5px 20px 5px 30px;
        }
      }
    }
  }
}
