@import "../../Variables.scss";

.testimonials-container {
  margin-top: 300px;
  margin-bottom: 300px;

  .testimonials-section {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 1400px;

    .section-header {
      height: max-content;
      width: 80%;
      position: relative;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .section-title {
        position: relative;
        width: max-content;
        margin: 70px 0;

        @media only screen and (max-width: 800px) {
          font-size: 18px;
        }
      }

      .section-illustration {
        background-image: url("/images/Testimonials_Images/testimonials-illustration.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        width: 200px;
        height: 250px;

        @media only screen and (max-width: 800px) {
          right: 0px;
          width: 150px;
          height: 200px;
        }
      }
    }

    .testimonials-bg {
      position: relative;
      width: 80%;
      height: 500px;
      border-radius: 30px;
      background: linear-gradient(#e0f0f9, #ffffff00);
      left: 50%;
      // top: 50%;
      transform: translate(-50%, 0);

      .read-more-button {
        position: absolute;
        top: -100px;

        @media only screen and (max-width: 800px) {
          position: relative;
          top: unset;
          left: 50%;
          transform: translate(-50%, 0%);
        }
      }

      .testimonials-small-screen {
        display: none;

        @media only screen and (max-width: 800px) {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          // margin-top: 10px;
          margin: auto;
          width: 100%;
          height: max-content;
          padding-bottom: 50px;

          .testimonial-box {
            margin: 20px auto 20px -3%;
            box-sizing: border-box;
            width: 106%;
            max-width: 800px;
          }
        }
      }

      .testimonials-large-screen {
        position: relative;

        @media only screen and (max-width: 800px) {
          display: none;
        }
      }

      .testimonial-box {
        position: absolute;
        width: max-content;
        max-width: 400px;
        padding: 20px 20px 40px 20px;
        height: fit-content;
        border-radius: 15px;
        background-color: white;
        border: 1px solid $color-blue;

        @media only screen and (max-width: 800px) {
          position: relative;
        }

        .testimonial-text {
          font-size: 14px;
          text-align: left;
        }

        .testimonial-icon {
          position: absolute;
          bottom: -20px;
          left: 20px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          background-color: $color-blue;
          color: white;
          font-size: 20px;
          line-height: 37px;
        }

        .testimonial-name {
          font-size: 16px;
          position: absolute;
          bottom: 10px;
          right: 20px;
        }
      }
    }

    @media only screen and (max-width: 650px) {
      background-position-x: -120px;
    }

    .boxed-description {
      top: 200px;
      left: 5%;
      width: 50vw;
      max-width: 600px;
      border-radius: 3px;

      .read-more-button {
        margin-top: 20px;
      }

      @media only screen and (max-width: 920px) {
        top: 600px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 70vw;
        max-width: 800px;
      }

      .description-subtext {
        // margin: 0 0 0;
        // max-width: 500px;
        text-align: left;

        @media only screen and (max-width: 920px) {
          text-align: left;
          margin: 0;
          padding: 0 0 20px 0;
        }
      }
    }
  }
}
