@import "../../Variables.scss";

.menu-button-holder {
  position: relative;
  height: fit-content;
  width: max-content;
  background-color: white;
  margin: 5px 0;
  padding: 4px 8px;
  box-shadow: 0px 0px 5px 1px rgb(237, 237, 237);
  border-radius: 25px;
  font-size: 14px;
  color: rgb(60, 60, 60);
  transition: background-color 0.5s;
  transition: padding 0.5s;
  border: 0.5px solid rgb(161, 161, 161);

  &.active {
    box-shadow: 0px 1px 5px 0px rgb(165, 165, 165);
    background-color: $color-blue;
    color: white;
    padding: 5px 9px;
    cursor: default;
    pointer-events: none;

    &:hover {
      background-color: $color-blue;
      color: white;
    }
  }

  &:hover {
    padding: 5px 9px;
    color: black;
    cursor: pointer;
  }

  .menu-button {
  }

  .menu-button.active {
  }
}
