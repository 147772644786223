.partnership-more-info-page {
  position: relative;
  margin: auto;
  width: 70vw;
  font-size: 16px;
  padding: 50px 0;
  .title {
    font-size: 28px;
    color: #00afef;
    text-align: center;
  }

  .contact-text {
    text-align: center;
  }

  .email-text {
    margin-left: 5px;
    color: #00afef;
  }

  .react-pdf__Page {
    display: flex;
    justify-content: center;
  }
}
