@import "../../Variables.scss";

.navigator-holder {
  position: relative;
  width: 100vw;
  height: max-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 300px;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }

  .navigator-question-box {
    position: relative;
    width: 300px;
    height: 100px;
    background-color: white;
    border: 1px solid $color-blue;
    box-shadow: 0px 0px 5px 5px rgb(229, 229, 229);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 10px 100px auto 0;

    @media only screen and (max-width: 700px) {
      margin: auto auto 50px auto;
    }

    .typewriter {
      overflow: hidden; /* Ensures the content is not revealed until the animation */
      border-right: 0.15em solid $color-blue; /* The typwriter cursor */
      white-space: nowrap; /* Keeps the content on a single line */
      margin: 0 auto; /* Gives that scrolling effect as the typing happens */
      letter-spacing: 0.15em; /* Adjust as needed */
      animation: typing 3.5s steps(25, end), blink-caret 0.75s step-end infinite;
    }

    /* The typing effect */
    @keyframes typing {
      from {
        width: 0;
      }
      to {
        width: 100%;
      }
    }

    /* The typewriter cursor effect */
    @keyframes blink-caret {
      from,
      to {
        border-color: transparent;
      }
      50% {
        border-color: $color-blue;
      }
    }

    .text {
      margin: auto;
      font-size: 20px;
    }
  }

  .navigator-answers-holder {
    position: relative;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 290px;

    .navigator-answer-button-holder {
      &:hover {
        .navivator-answer-connecting-line {
          border: 1.5px solid $color-blue;
        }

        @media only screen and (max-width: 700px) {
          #become-a-coach-connecting-line {
            border-right-color: $color-blue;
          }
        }
      }
      .navivator-answer-connecting-line {
        position: absolute;
        z-index: -1;
        border: 1.5px solid rgb(208, 208, 208);
        border-radius: 20px;
        transition: border 0.5s;
      }

      #get-coaching-connecting-line {
        height: 1px;
        width: 400px;
        border-bottom-color: transparent;
        border-right-color: transparent;
        top: 13%;
        left: -100%;

        @media only screen and (max-width: 700px) {
          width: 1px;
          height: 100px;
          left: 50%;
          top: 0%;
          transform: translate(-50%, -50%);
        }
      }

      #partnership-connecting-line {
        height: 100px;
        width: 300px;
        border-top-color: transparent;
        border-right-color: transparent;
        top: 15%;
        left: -90%;
        border-radius: 15px;

        @media only screen and (max-width: 700px) {
          height: 200px;
          width: 50px;
          left: 50%;
          top: -20%;
          transform: translate(-230%, -0);
        }
      }

      #become-a-coach-connecting-line {
        height: 150px;
        width: 400px;
        border-top-color: transparent;
        border-right-color: transparent;
        top: 30%;
        left: -170%;

        @media only screen and (max-width: 700px) {
          height: 300px;
          width: 50px;
          left: 50%;
          top: -20%;
          transform: translate(130%, 0);
          border-left-color: transparent;
          border-right-color: rgb(208, 208, 208);
          border-radius: 10px;
        }
      }

      .navigator-answer-button {
        width: max-content;
        border: 1px solid $color-light-gray;
        box-shadow: 0px 0px 5px 5px rgb(229, 229, 229);
        padding: 20px;
        margin: 10px 0 10px auto;
        border-radius: 10px;
        background-color: white;
        cursor: pointer;
        transition: background-color 0.5s;

        @media only screen and (max-width: 800px) {
          margin: 10px auto;
        }

        &:hover {
          border: 1px solid $color-blue;
          background-color: $color-blue;
          color: white;
        }
      }
    }
  }
}
