.About-section {
  .App-header {
    background-color: white;
    min-height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: black;
    background-image: url("/images/header-full-bg.svg");
    background-size: cover;
    background-position: center 280px;
    background-repeat: no-repeat;
    height: 86vh;
    // margin-bottom: 500px;

    .cc-logo {
      width: 60%;
      max-width: 380px;
    }

    .company-name {
      margin-top: 15px;
      font-size: 44px;
      color: #00afef;
      text-shadow: 2px 2px #dfdfdf;
    }

    .slogan {
      position: relative;
      color: black;
      font-size: 20px;
      margin-top: 20px;
      width: 60%;
      max-width: 800px;
      text-align: center;
    }
  }
}
