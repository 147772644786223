.Contact-section {
  background-image: url("/images/connect-bg.svg");
  background-size: 100%;
  background-position: center 50px;
  margin-top: 100px;
  height: 70vh;

  .contact-section-text {
    position: relative;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 200px;
  }

  .contact-buttons-holder {
    width: 50%;
    height: 100px;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .contact-button {
      /* width: 100px;
        height: 100px; */
      position: relative;
      display: flex;
      /* margin: auto; */
    }

    .email-button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
    }

    .email-icon {
      width: 50px;
      height: 50px;
      min-width: 50px;
      min-height: 50px;
      background-image: url("/images/email-icon.svg");
      background-repeat: no-repeat;
      background-size: cover;
      margin-right: 10px;
    }

    .email-text {
      width: fit-content;
      margin: auto 0;
      font-size: 16px;
    }
  }
}
