.menu {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 99999999;

  .menu-icon {
    width: 50px;
    height: 50px;
    background-image: url("/images/menu-icon-shadow.png");
    background-size: cover;
    cursor: pointer;
    transition: all 0.5s;
    // translate: height 0.5s;

    &:hover {
      width: 52px;
      height: 52px;
    }
  }

  .menu-buttons-holder {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    height: 0px;
    overflow: hidden;
    opacity: 0;
    transition: all 0.5s;

    &.opened {
      height: 242px;
      opacity: 1;
    }
  }
}
